import React, { useState, useEffect } from 'react';
import './App.css'; // Importation de la feuille de style
import L from 'leaflet';

function GeoLocationPage() {
  const [location, setLocation] = useState(null);
  const [map, setMap] = useState(null);

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          const newLocation = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
          };
          setLocation(newLocation);

          if (map) {
            const newLatLng = new L.LatLng(newLocation.latitude, newLocation.longitude);
            map.setView(newLatLng, 13);
            L.marker(newLatLng).addTo(map);
          }
        },
        error => {
          console.error(error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  useEffect(() => {
    if (location && !map) {
      const initialLatLng = new L.LatLng(location.latitude, location.longitude);
      const mapInstance = L.map('map').setView(initialLatLng, 13);

      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      }).addTo(mapInstance);

      L.marker(initialLatLng).addTo(mapInstance);
      setMap(mapInstance);
    }
  }, [location, map]);

  return (
    <div className="App-header"> {/* Utilisation de la classe App-header */}
      <h2>Page de géolocalisation</h2>
      <button className="capture-button" onClick={getLocation}>+</button> {/* Utilisation de la classe capture-button */}
      {location && (
        <div>
          <h3>Position actuelle :</h3>
          <p>Latitude : {location.latitude}</p>
          <p>Longitude : {location.longitude}</p>
          <div id="map" style={{ height: '400px', width: '100%' }}></div> {/* Ajout de la div pour la carte */}
        </div>
      )}
    </div>
  );
}

export default GeoLocationPage;
