import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';
import './App.css';
import GeolocationPage from './GeoLocationPage';
import WebcamPage from './WebcamPage';
import OTPPage from './OTPPage';
import callIcon from './icons/call.png';
import vibrateIcon from './icons/vibrate.webp';
import batteryIcon from './icons/battery.png';
import wifiIcon from './icons/wifi.webp';

function App() {
  const [batteryLevel, setBatteryLevel] = useState(null);
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [notification, setNotification] = useState('');

  useEffect(() => {
    console.log('Initial online status:', navigator.onLine);
    
    if ('getBattery' in navigator) {
      navigator.getBattery().then(battery => {
        setBatteryLevel(Math.floor(battery.level * 100));

        battery.addEventListener('levelchange', () => {
          setBatteryLevel(Math.floor(battery.level * 100));
        });
      });
    }

    const handleOnline = () => {
      setIsOnline(true);
      setNotification('Vous êtes en ligne');
      console.log('App is online');
      setTimeout(() => setNotification(''), 3000);
    };

    const handleOffline = () => {
      setIsOnline(false);
      setNotification('Vous êtes hors ligne');
      console.log('App is offline');
      setTimeout(() => setNotification(''), 3000);
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  const handleVibrate = () => {
    if (navigator.vibrate) {
      navigator.vibrate(200); // Vibrer pendant 200ms
    }
  };

  const handleCall = () => {
    const phoneNumber = prompt('Entrez le numéro de téléphone à appeler :');
    if (phoneNumber) {
      window.location.href = `tel:${phoneNumber}`;
    }
  };

  return (
    <Router>
      <div className="App">
        <header>
          <h1>Application PWA</h1>
          <nav>
            <ul>
              <li><Link to="/geolocation">Géolocalisation</Link></li>
              <li><Link to="/webcam">Webcam</Link></li>
              <li><Link to="/otp">OTP</Link></li>
            </ul>
          </nav>
          <div className="icons">
            <img src={callIcon} alt="Appel" onClick={handleCall} />
            <img src={vibrateIcon} alt="Vibration" onClick={handleVibrate} />
            {batteryLevel !== null && (
              <div className="battery">
                <img src={batteryIcon} alt="Niveau de batterie" />
                <span>{batteryLevel}%</span>
              </div>
            )}
            {/* {isOnline ? (
              <img src={wifiIcon} alt="Wi-Fi" className="wifi-icon" />
            ) : (
              <img src={wifiIcon} alt="Wi-Fi" className="wifi-icon offline" />
            )} */}
          </div>
        </header>
        {notification && <div className="notification">{notification}</div>}
        <Routes>
          <Route path="/geolocation" element={<GeolocationPage />} />
          <Route path="/webcam" element={<WebcamPage />} />
          <Route path="/otp" element={<OTPPage />} />
          <Route path="/" element={<HomePage />} />
        </Routes>
      </div>
    </Router>
  );
}

function HomePage() {
  return (
    <div>
      <h2>Bienvenue sur la page d'accueil !</h2>
      <p>Cliquez sur l'un des liens ci-dessus pour accéder à une fonctionnalité.</p>
    </div>
  );
}

export default App;
