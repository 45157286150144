import React, { useState } from 'react';
import './App.css';

function OTPPage() {
  const [otp, setOtp] = useState('');
  const [error, setError] = useState('');

  const handleOTPInput = (event) => {
    setOtp(event.target.value);
  };

  const requestOTP = async () => {
    if ('OTPCredential' in window) {
      try {
        const content = await navigator.credentials.get({
          otp: { transport: ['sms'] },
          signal: new AbortController().signal
        });
        setOtp(content.code);
      } catch (err) {
        setError(`Failed to read OTP: ${err.message}`);
      }
    } else {
      setError('WebOTP API is not supported on this browser.');
    }
  };

  return (
    <div className="App-header">
      <h2>Message OTP</h2>
      <input
        type="text"
        value={otp}
        onChange={handleOTPInput}
        placeholder="Message OTP"
        className="otp-input"
      />
      <button onClick={requestOTP} className="request-otp-button">
        Lire le SMS OTP
      </button>
      {error && <p className="error-message">{error}</p>}
    </div>
  );
}

export default OTPPage;
