import React, { useRef, useState, useEffect } from 'react';
import Webcam from "react-webcam";
import './App.css';

function WebcamPage() {
  const webcamRef = useRef(null);
  const [capturedImages, setCapturedImages] = useState([]);

  useEffect(() => {
    const storedImages = JSON.parse(localStorage.getItem('capturedImages'));
    if (storedImages) {
      setCapturedImages(storedImages);
    }
  }, []);
  

  const captureImage = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    if (capturedImages.length < 5) {
      const newImages = [...capturedImages, imageSrc];
      setCapturedImages(newImages);
      localStorage.setItem('capturedImages', JSON.stringify(newImages));
      showNotification("Photo prise", "Vous avez capturé une nouvelle photo !");
    }
  };

  const clearImages = () => {
    setCapturedImages([]);
    localStorage.removeItem('capturedImages');
  };

  const showNotification = (title, message) => {
    if (Notification.permission === "granted") {
      new Notification(title, { body: message });
    } else if (Notification.permission !== "denied") {
      Notification.requestPermission().then(permission => {
        if (permission === "granted") {
          new Notification(title, { body: message });
        }
      });
    }
  };

  return (
    <div className="App-header">
      <h2>Webcam</h2>
      <div className="container">
        <div className="column">
          <Webcam
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            className="webcam"
          />
          <button className="capture-button" onClick={captureImage} disabled={capturedImages.length >= 5}>
            +
          </button>
        </div>
        <div className="column">
          {capturedImages.map((image, index) => (
            <div key={index}>
              <img src={image} alt={`Captured ${index}`} />
            </div>
          ))}
          {capturedImages.length > 0 && (
            <button className="capture-button" onClick={clearImages}>-</button>
          )}
        </div>
      </div>
    </div>
  );
}

export default WebcamPage;
